import * as Sentry from '@sentry/browser';
import { ApolloError } from 'apollo-client';

function reportApolloError(apolloError) {
  const { graphQLErrors, networkError } = apolloError;

  Sentry.withScope(scope => {
    scope.setExtra('networkError', networkError);
    scope.setExtra('graphQLErrors', graphQLErrors);

    return Sentry.captureException(apolloError);
  });
}

export function reportError(error) {
  if (error instanceof ApolloError) {
    return reportApolloError(error);
  }

  return Sentry.captureException(error);
}

export class UnexpectedMutationResultError extends Error {
  constructor({ mutation, variables, result, mutationSuccessCheck }) {
    super('A mutation returned an unexpected result.');

    this.name = 'UnexpectedMutationResultError';
    this.mutation = mutation.loc.source.body;
    this.variables = variables;
    this.result = result;
    this.mutationSuccessCheck = mutationSuccessCheck.toString();
  }
}

export class AjaxError extends Error {
  constructor({ responseStatus, responseMessage, requestUrl, requestOpts }) {
    super('An error occurred during an Ajax call.');

    this.name = 'AjaxError';
    this.responseStatus = responseStatus;
    this.responseMessage = responseMessage;
    this.requestUrl = requestUrl;
    this.requestOpts = requestOpts;
  }
}
