import React from 'react';
import Dialog from 'components/Dialog';
import Button from 'components/Button';

import { reloadPage } from 'utils/utils';

export default class GenericErrorDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reloadClicked: false };
    this.handleReloadClick = this.handleReloadClick.bind(this);
  }

  handleReloadClick() {
    this.setState({ reloadClicked: true }, reloadPage);
  }

  render() {
    const { reloadClicked } = this.state;

    return (
      <Dialog
        title="Unknown error"
        message={[
          {
            text:
              'Please reload by clicking below.  If the problem persists, please contact customer support.',
          },
        ]}
        actions={[
          <Button
            key="reload"
            label="Reload"
            disabled={reloadClicked}
            inProgress={reloadClicked}
            onClick={this.handleReloadClick}
          />,
        ]}
      />
    );
  }
}
