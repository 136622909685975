/* eslint-disable no-undef */
/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: ['class'],
  content: ['./src/**/*.{js,jsx,ts,tsx,mdx}'],
  theme: {
    fontFamily: {
      sans: [
        'Red Hat Text',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Helvetica Neue',
        'Arial',
        'sans-serif',
      ],
      serif: ['Outfit', 'serif'],
      display: ['Outfit', 'serif'],
      mono: [
        'ui-monospace',
        'SFMono-Regular',
        'Menlo',
        'Monaco',
        'Consolas',
        'Liberation Mono',
        'Courier New',
        'monospace',
      ],
    },
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px',
      },
    },
    extend: {
      screens: {
        xs: '375px',
      },
      maxWidth: {
        xs: '15rem',
      },
      boxShadow: {
        DEFAULT:
          '0px 3px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.05)',
      },
      colors: {
        gray: {
          50: '#FAFAFA',
          100: '#F1F2F3',
          200: '#E7E8E9',
          300: '#D9DCDD',
          400: '#C1C5C8',
          500: '#989FA4',
          600: '#6E767C',
          700: '#565D61',
          800: '#373B3E',
          900: '#242729',
        },
        brand: {
          50: '#F0FAF5',
          100: '#E1F4EE',
          200: '#BCEBDE',
          300: '#6FE2CD',
          400: '#6FE2CD',
          500: '#29C2B5',
          600: '#19A4A0',
          700: '#0D8082',
          800: '#056066',
          900: '#003941',
        },
        burgundy: {
          50: '#FCF7FB',
          100: '#FAF0F6',
          200: '#F6E4F0',
          300: '#ECCAE0',
          400: '#E0A9CD',
          500: '#D07CB2',
          600: '#BE5B9B',
          700: '#A14581',
          800: '#763760',
          900: '#522A44',
        },
        blue: {
          50: '#F1F8F9',
          100: '#E6F3F5',
          200: '#DBEEF0',
          300: '#B6DCE1',
          400: '#87CBD4',
          500: '#35B0C0',
          600: '#149CAE',
          700: '#057180',
          800: '#118393',
          900: '#03464F',
        },
        sand: {
          50: '#FCF9F8',
          100: '#F8F4F2',
          200: '#F2EAE6',
          300: '#E3D2C9',
          400: '#D0B3A4',
          500: '#B98E79',
          600: '#97674E',
          700: '#865B46',
          800: '#6B4938',
          900: '#402B21',
        },
        green: {
          50: '#F7FDF9',
          100: '#EFFAF4',
          200: '#DCF4E6',
          300: '#C8EED8',
          400: '#A0E2BA',
          500: '#6ED397',
          600: '#3ABF70',
          700: '#31A05D',
          800: '#237142',
          900: '#123B22',
        },
        orange: {
          50: '#FFFCFA',
          100: '#FFF8F5',
          200: '#FFEEE5',
          300: '#FFE0D1',
          400: '#FFBE9F',
          500: '#FFA175',
          600: '#FF7B3D',
          700: '#F04C00',
          800: '#AD3700',
          900: '#521A00',
        },
        red: {
          50: '#FFF5F5',
          100: '#FFE6E6',
          200: '#FECDCD',
          300: '#F7A1A1',
          400: '#EA7676',
          500: '#D95454',
          600: '#BF3B3B',
          700: '#A32424',
          800: '#8D0C0C',
          900: '#680303',
        },
        navy: {
          deprecated: true,
          50: '#354c98',
          100: '#2b428e',
          200: '#213884',
          300: '#172e7a',
          400: '#0d2470',
          500: '#031a66',
          600: '#00105c',
          700: '#000652',
          800: '#000048',
          900: '#00003e',
        },
        gold: {
          deprecated: true,
          50: '#F8F6F1',
          100: '#F0EAE1',
          200: '#E2D7C5',
          300: '#D8C9B1',
          400: '#CCB899',
          500: '#C1AA85',
          600: '#B59A6D',
          700: '#9C7E4F',
          800: '#735D3A',
          900: '#4E3F27',
        },
      },
      fontSize: {
        xxs: '0.625rem', // 10px
        xs: '0.75rem', // 12px
        sm: '0.875rem', // 14px
        base: '1rem', // 16px
        lg: '1.125rem', // 18px
        xl: '1.375rem', // 22px (default: 20px)
        '2xl': '1.5rem', // 24px
        '3xl': '2rem', // 32px (default: 30px)
        '4xl': '2.25rem', // 36px
        '5xl': '3rem', // 48px,
        '6xl': '3.75rem', // 60px,
        '7xl': '4.5rem', // 72px,
        '8xl': '6rem', // 96px,
        '9xl': '8rem', // 128px,
      },
      keyframes: {
        slideDownAndFade: {
          from: { opacity: 0, transform: 'translateY(-2px)' },
          to: { opacity: 1, transform: 'translateY(0)' },
        },
        slideLeftAndFade: {
          from: { opacity: 0, transform: 'translateX(2px)' },
          to: { opacity: 1, transform: 'translateX(0)' },
        },
        slideUpAndFade: {
          from: { opacity: 0, transform: 'translateY(2px)' },
          to: { opacity: 1, transform: 'translateY(0)' },
        },
        slideRightAndFade: {
          from: { opacity: 0, transform: 'translateX(-2px)' },
          to: { opacity: 1, transform: 'translateX(0)' },
        },
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      animation: {
        slideDownAndFade:
          'slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideLeftAndFade:
          'slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideUpAndFade: 'slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideRightAndFade:
          'slideRightAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
    },
  },

  plugins: [
    require('tailwind-scrollbar-hide'),
    require('@tailwindcss/forms'),
    exposeColorsAsCSSVariables,
    require("tailwindcss-animate"),
  ],
};

function exposeColorsAsCSSVariables({ addBase, theme }) {
  function extractColorVars(colorObj, colorGroup = '') {
    return Object.keys(colorObj).reduce((vars, colorKey) => {
      const value = colorObj[colorKey];

      const newVars =
        typeof value === 'string'
          ? { [`--color${colorGroup}-${colorKey}`]: value }
          : extractColorVars(value, `-${colorKey}`);

      return { ...vars, ...newVars };
    }, {});
  }

  addBase({
    ':root': extractColorVars(theme('colors')),
  });
}
