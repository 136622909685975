import { GET_SELECTED } from 'gql';

const resolvers = {
  Mutation: {
    addOrRemoveSelected: (_, { input: { key, value } }, { cache }) => {
      const { selected } = cache.readQuery({ query: GET_SELECTED });
      const newSelected = selected.filter(item => item.key !== key);

      if (newSelected.length === selected.length) {
        newSelected.push({ key, value });
      }

      cache.writeQuery({
        query: GET_SELECTED,
        data: { selected: newSelected },
      });

      return newSelected;
    },
  },
};

export default resolvers;
