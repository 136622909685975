import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { createGenerateClassName } from '@material-ui/core/styles';

import ThemedComponent from 'components/ThemedComponent';
import { ApolloProvider } from '@apollo/react-common';
import BlockingErrorBoundary from 'components/BlockingErrorBoundary';
import { lazyLoad } from 'utils/ajax';
import getApolloClient from 'utils/apollo';
import { redirect } from 'utils/utils';
import { FlagsProvider } from 'utils/flags';
import { datadogRum } from '@datadog/browser-rum';

import UserParticipationStatusButtonContext from 'components/UserParticipationStatusButton/context';

const Badge = lazyLoad(() => import('@material-ui/core/Badge'));
const OrganizationLogo = lazyLoad(() => import('components/OrganizationLogo'));
const SelectCheckbox = lazyLoad(() => import('components/SelectCheckbox'));
const NewOpportunityButton = lazyLoad(() =>
  import('components/NewOpportunityButton'),
);
const UserParticipationStatusButton = lazyLoad(() =>
  import('components/UserParticipationStatusButton/StandaloneComponent'),
);

const RecruitingStatusButton = lazyLoad(() =>
  import('components/RecruitingStatusButton/StandaloneComponent'),
);

const FeaturedStatusButton = lazyLoad(() =>
  import('pages/AdminOpportunityDetail/components/FeaturedStatusButton'),
);

const ApproveDeclineWidget = lazyLoad(() =>
  import('components/ApproveDeclineWidget/StandaloneComponent'),
);

const RecruitmentEmailDialog = lazyLoad(() =>
  import('components/RecruitmentEmailDialog'),
);

const Tooltip = lazyLoad(() => import('components/Tooltip'));

const CoordinatorNoteWidget = lazyLoad(() =>
  import('pages/AdminOpportunityDetail/pages/CoordinatorNoteWidget'),
);

const BulkRecruitmentActionsWidget = lazyLoad(() =>
  import('pages/AdminOpportunityListing/components/BulkRecruitmentActionsWidget'),
);

const BulkSelectCheckbox = lazyLoad(() =>
  import('pages/AdminOpportunityListing/components/BulkSelectCheckbox'),
);

const BulkParticipantsActionWidget = lazyLoad(() =>
  import('pages/AdminOpportunityDetail/components/BulkParticipantsActionWidget'),
);

const WelcomeModal = lazyLoad(() =>
  import('pages/AdminOpportunityListing/components/WelcomeModal'),
);

const BulkApproveDeclineWidget = lazyLoad(() =>
  import('pages/AdminOpportunityListing/components/BulkApproveDeclineWidget'),
);

const SharedExternallyTooltip = lazyLoad(() =>
  import('pages/AdminOpportunityListing/components/SharedExternallyTooltip'),
);

const DashboardIsFeaturedTooltip = lazyLoad(() =>
  import('pages/AdminOpportunityListing/components/DashboardIsFeaturedTooltip'),
);

const DetailIsFeaturedTooltip = lazyLoad(() =>
  import('pages/AdminOpportunityDetail/components/DetailIsFeaturedTooltip'),
);

const DiscoveryIsFeaturedTooltip = lazyLoad(() =>
  import('pages/OpportunityDetail/components/DiscoveryIsFeaturedTooltip'),
);

const SentToCell = lazyLoad(() =>
  import('pages/AdminOpportunityListing/components/SentToCell'),
);

const OpportunityQueryWidget = lazyLoad(() =>
  import('components/OpportunityQueryWidget'),
);

const GeofilterDropdown = lazyLoad(() =>
  import('components/GeofilterDropdown'),
);

const OpportunityDetail = lazyLoad(() => import('pages/OpportunityDetail'));
const Preferences = lazyLoad(() => import('pages/Preferences'));
const VolunteerManagement = lazyLoad(() =>
  import('pages/AdminOpportunityDetail/pages/VolunteerManagement'),
);
const OpportunityListSummary = lazyLoad(() =>
  import('pages/OpportunityList/components/OpportunityListSummary'),
);
const EmptyOpportunityList = lazyLoad(() =>
  import('pages/OpportunityList/components/EmptyOpportunityList'),
);
const LegalServiceOnboardingPage = lazyLoad(() =>
  import('pages/LegalServiceOnboarding'),
);
const NetworkPage = lazyLoad(() => import('pages/Network'));
const OrganizationProfilePage = lazyLoad(() =>
  import('pages/OrganizationProfile'),
);
const OrganizationSettingsPage = lazyLoad(() =>
  import('pages/OrganizationSettings'),
);
const LegalServerImports = lazyLoad(() => import('pages/LegalServer'));
const ListingsPage = lazyLoad(() => import('pages/Listings'));

Sentry.init({
  dsn: window.paladin.config.SENTRY_DSN_FRONTEND,
  environment: window.paladin.config.SENTRY_ENVIRONMENT,
  release: __RELEASE__,
  integrations: [
    new Sentry.Integrations.RewriteFrames({
      root: window.paladin.config.PROJECT_ROOT,
    }),
  ],
});

Sentry.configureScope(scope => {
  scope.setUser({
    id: window.paladin.config.SENTRY_TRACKING_USER_ID,
  });
});

if (
  window.paladin.config.DD_RUM_CLIENT_TOKEN &&
  window.paladin.config.DD_RUM_APPLICATION_ID
) {
  datadogRum.init({
    clientToken: window.paladin.config.DD_RUM_CLIENT_TOKEN,
    applicationId: window.paladin.config.DD_RUM_APPLICATION_ID,
  });
}

const apolloClient = getApolloClient({ selected: [] });

const bundleAbbreviation = 'p';
const generateClassName = createGenerateClassName({
  seed: bundleAbbreviation,
});

const mountPage = (Component, props, mountPoint, context) => {
  const { componentProps, flags } = props;
  ReactDOM.render(
    <FlagsProvider flags={flags || {}}>
      <ApolloProvider client={apolloClient}>
        <React.Suspense fallback={null}>
          <ThemedComponent generateClassName={generateClassName}>
            <BlockingErrorBoundary>
              {context ? (
                <context.Provider value={componentProps}>
                  <Component {...componentProps} />
                </context.Provider>
              ) : (
                <Component {...componentProps} />
              )}
            </BlockingErrorBoundary>
          </ThemedComponent>
        </React.Suspense>
      </ApolloProvider>
    </FlagsProvider>,
    mountPoint,
  );
};

const mountBundle = (Component, props, mountPoint) => {
  ReactDOM.render(
    <React.Suspense fallback={null}>
      <Component {...props} />
    </React.Suspense>,
    mountPoint,
  );
};

window.paladin.renderWelcomeModal = function(properties, mountPoint) {
  mountPage(WelcomeModal, { generateClassName, ...properties }, mountPoint);
};

window.paladin.renderSelectCheckbox = function(properties, mountPoint) {
  mountPage(SelectCheckbox, { generateClassName, ...properties }, mountPoint);
};

window.paladin.renderBulkSelectCheckbox = function(properties, mountPoint) {
  mountPage(
    BulkSelectCheckbox,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderBulkParticipantsActionWidget = function(
  properties,
  mountPoint,
) {
  mountPage(
    BulkParticipantsActionWidget,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderBulkRecruitmentActionsWidget = function(
  properties,
  mountPoint,
) {
  mountPage(
    BulkRecruitmentActionsWidget,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderBulkApproveDeclineWidget = function(
  properties,
  mountPoint,
) {
  mountPage(
    BulkApproveDeclineWidget,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderSentToCell = function(properties, mountPoint) {
  mountPage(SentToCell, { generateClassName, ...properties }, mountPoint);
};

window.paladin.renderBadge = function(properties, mountPoint) {
  mountPage(Badge, { generateClassName, ...properties }, mountPoint);
};

window.paladin.renderNewOpportunityButton = function(properties, mountPoint) {
  mountPage(
    NewOpportunityButton,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderSharedExternallyTooltip = function(mountPoint) {
  mountPage(SharedExternallyTooltip, { generateClassName }, mountPoint);
};

window.paladin.renderDashboardIsFeaturedTooltip = function(
  properties,
  mountPoint,
) {
  mountPage(
    DashboardIsFeaturedTooltip,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderDetailIsFeaturedTooltip = function(
  properties,
  mountPoint,
) {
  mountPage(
    DetailIsFeaturedTooltip,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderDiscoveryIsFeaturedTooltip = function(mountPoint) {
  mountPage(DiscoveryIsFeaturedTooltip, { generateClassName }, mountPoint);
};

window.paladin.renderTooltip = function(properties, mountPoint) {
  mountPage(Tooltip, { generateClassName, ...properties }, mountPoint);
};

window.paladin.renderCoordinatorNoteWidget = function(properties, mountPoint) {
  mountPage(
    CoordinatorNoteWidget,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderApproveDeclineWidget = function(properties, mountPoint) {
  mountPage(
    ApproveDeclineWidget,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderUserParticipationStatusButton = function({
  mountPoint,
  ...props
}) {
  props.componentProps.generateClassName = generateClassName;
  mountPage(
    UserParticipationStatusButton,
    props,
    mountPoint,
    UserParticipationStatusButtonContext,
  );
};

window.paladin.renderRecruitingStatusButton = function(properties, mountPoint) {
  mountPage(
    RecruitingStatusButton,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderFeaturedStatusButton = function(properties, mountPoint) {
  mountPage(
    FeaturedStatusButton,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderOrganizationLogo = function(properties, mountPoint) {
  mountBundle(
    OrganizationLogo,
    { generateClassName, ...properties },
    mountPoint,
  );
};

window.paladin.renderRecruitmentEmailDialog = function({
  mountPoint,
  ...props
}) {
  const closeDialog = () => ReactDOM.unmountComponentAtNode(mountPoint);
  mountPage(
    RecruitmentEmailDialog,
    { ...props, componentProps: { ...props.componentProps, closeDialog } },
    mountPoint,
  );
};

window.paladin.renderPreferences = function({ mountPoint, ...props }) {
  mountPage(Preferences, props, mountPoint);
};

window.paladin.renderVolunteerManagement = function({ mountPoint, ...props }) {
  mountPage(VolunteerManagement, props, mountPoint);
};

window.paladin.renderNetworkPage = function({ mountPoint, ...props }) {
  mountPage(NetworkPage, props, mountPoint);
};

window.paladin.renderOrganizationProfilePage = function({
  mountPoint,
  ...props
}) {
  mountPage(OrganizationProfilePage, props, mountPoint);
};

window.paladin.renderOrganizationSettingsPage = function({
  mountPoint,
  ...props
}) {
  mountPage(OrganizationSettingsPage, props, mountPoint);
};

window.paladin.renderLegalServerImportsPage = function({
  mountPoint,
  ...props
}) {
  mountPage(LegalServerImports, props, mountPoint);
};

window.paladin.renderOpportunityDetailSidebar = function({
  mountPoint,
  ...props
}) {
  mountPage(OpportunityDetail, props, mountPoint);
};

window.paladin.renderOpportunityListSummary = function({
  mountPoint,
  ...props
}) {
  mountPage(OpportunityListSummary, props, mountPoint);
};

window.paladin.renderEmptyOpportunityList = function({ mountPoint, ...props }) {
  mountPage(EmptyOpportunityList, props, mountPoint);
};

window.paladin.renderOpportunityQueryWidget = function({
  mountPoint,
  ...props
}) {
  mountPage(OpportunityQueryWidget, props, mountPoint);
};

window.paladin.renderGeofilterDropdown = function({ mountPoint, ...props }) {
  mountPage(GeofilterDropdown, props, mountPoint);
};

window.paladin.renderNavUtils = function() {
  import('utils/navUtils').then(utils => utils.default());
};

window.paladin.renderLegalServiceOnboarding = function({
  mountPoint,
  ...props
}) {
  mountPage(LegalServiceOnboardingPage, props, mountPoint);
};

window.paladin.renderListingsPage = function({ mountPoint, ...props }) {
  mountPage(ListingsPage, props, mountPoint);
};

window.paladin.redirect = redirect;
