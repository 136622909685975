import { ApolloClient } from 'apollo-client';
import merge from 'lodash/merge';
import selectCheckboxResolvers from 'components/SelectCheckbox/resolvers';
import adminOpportunityListingResolvers from 'pages/AdminOpportunityListing/resolvers';
import { concat } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Cookie from 'js-cookie';
import 'whatwg-fetch';

export default function getApolloClient(initialCacheData = undefined) {
  const httpLink = createHttpLink({
    uri: window.paladin.config.GRAPHQL_ENDPOINT,
    credentials: 'same-origin',
    headers: {
      'X-CSRFTOKEN': Cookie.get(window.paladin.config.CSRF_COOKIE_NAME),
    },
    fetch,
  });

  const cache = new InMemoryCache({ freezeResults: true });
  if (initialCacheData) {
    cache.writeData({ data: initialCacheData });
  }

  return new ApolloClient({
    link: concat(new RetryLink(), httpLink),
    cache,
    resolvers: merge(selectCheckboxResolvers, adminOpportunityListingResolvers),
  });
}
