import React from 'react';
import MaterialDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  bold: {
    fontWeight: '500',
  },
  content: {
    '& > div:not(:first-child)': {
      paddingTop: theme.spacing(3),
    },
  },
});

class DialogContent extends React.Component {
  buildMessageLine = (msgObj, i) => {
    const { classes } = this.props;

    const className = msgObj.bold ? classes.bold : '';
    const { text, linkHref, linkTitle } = msgObj;

    return (
      <DialogContentText
        key={i}
        id="alert-dialog-description"
        className={className}
      >
        {text}
        {linkHref && linkTitle && (
          <React.Fragment>
            {` `}
            <Link href={linkHref} target="_blank" rel="noopener">
              {linkTitle}
            </Link>
          </React.Fragment>
        )}
      </DialogContentText>
    );
  };

  render() {
    const { classes, message, children } = this.props;

    if (!message && !children) {
      return null;
    }

    return (
      <React.Fragment>
        <MaterialDialogContent className={classes.content}>
          {message && <div>{message.map(this.buildMessageLine)}</div>}
          {children && <div>{children}</div>}
        </MaterialDialogContent>
        <Divider light />
      </React.Fragment>
    );
  }
}

DialogContent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      bold: PropTypes.bool,
      linkHref: PropTypes.string,
      linkTitle: PropTypes.string,
    }),
  ),
  children: PropTypes.node,
};

DialogContent.defaultProps = {
  children: undefined,
  message: undefined,
};

export default withStyles(styles)(DialogContent);
