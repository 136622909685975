import { createMuiTheme } from '@material-ui/core/styles';
import { theme } from '../../../../ui/tailwind.config.cjs';

const { colors } = theme.extend;

const white = '#FFFFFF';
// Green
const paladinGreen = colors.brand['700'];
const paladinGreenExtraLight = colors.brand['50'];
const paladinGreenLight = colors.brand['100'];
const paladinGreenLightTint = colors.brand['200'];
const paladinGreenDark = colors.brand['900'];
const paladinGreenExtraDark = colors.brand['900'];
// Navy
const paladinNavy = paladinGreenDark;
// Gray
const paladinNeutralGrayLight = '#545459';
const paladinNeutralGrayExtraLight = '#E0DEDB';
const paladinGreenGrayLight = '#9AB5B5';
const paladinNeutralGray = '#4C4E56';
const paladinWarmLightGray = '#E6E5E5';
const paladinBackgroundGray = '#FAFBFC';
const paladinGrayLighter = '#979797';
// Gold/yellow
const paladinGoldSolid = '#B59A6D';
const paladinGold100 = '#F0EAE1';
const paladinGold300 = '#D8C9B1';
const paladinGold700 = '#9C7E4F';
const paladinYellow = '#FFFCE8';
// Red
const paladinRed = '#C05B5B';
const paladinRedLight = '#EBCBCB';
const paladinRedLightTint = '#D3B6B6';
const paladinRedDark = '#9A3F3F';
const errorRed = '#CC0000';

const spacingUnit = 10;
const boxShadow =
  '0 2px 5px 0 rgba(3,26,102,0.05), 0 0 10px 0 rgba(3,26,102,0.05)';

const baseFontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Helvetica Neue',
  'Arial',
  'sans-serif',
].join(',');

const titleFontFamily = `Outfit,${baseFontFamily}`;
const textFontFamily = `Red Hat Text,${baseFontFamily}`;

const paladinTheme = createMuiTheme({
  palette: {
    primary: {
      main: paladinGreen,
      light: paladinGreenLight,
      extraLight: paladinGreenExtraLight,
      lightTint: paladinGreenLightTint,
      dark: paladinGreenDark,
      extraDark: paladinGreenExtraDark,
      contrastText: white,
      emphasis: paladinGoldSolid,
    },
    text: {
      primary: paladinNeutralGray,
    },
    secondary: { main: paladinNavy },
    error: { main: errorRed },
    disabledOpacity: 0.2,
    yellow: {
      main: paladinYellow,
    },
    gray: {
      extraLight: paladinNeutralGrayExtraLight,
      lighter: paladinGrayLighter,
      light: paladinNeutralGrayLight,
      main: paladinNeutralGray,
      greenLight: paladinGreenGrayLight,
      warm: paladinWarmLightGray,
      ...colors.gray,
    },
    gold: {
      '100': paladinGold100,
      '300': paladinGold300,
      '700': paladinGold700,
    },
    navy: paladinNavy,
    ...colors,
    red: {
      ...colors.red,
      light: paladinRedLight,
      lightTint: paladinRedLightTint,
      main: paladinRed,
      dark: paladinRedDark,
    },
    blue: { ...colors.blue },
  },
  spacing: spacingUnit,
  typography: {
    fontFamily: textFontFamily,
    display: titleFontFamily,
    fontWeight: 400,
  },
  customStyles: {
    boxShadow:
      '0px 0px 10px rgba(3, 26, 102, 0.05), 0px 2px 5px rgba(3, 26, 102, 0.05)',
    label: {
      fontSize: '14px',
      fontWeight: 500,
    },
    requiredLabel: {
      '&:after': { color: paladinGoldSolid, content: '" *"' },
    },
    colors: { neutralGrayExtraLight: paladinNeutralGrayExtraLight },
    selectWidths: {
      tiny: '126px',
      small: '300px',
      medium: '380px',
      full: '100%',
    },
    opForm: {
      horizontalGroup: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      formItem: {
        marginBottom: spacingUnit * 4,
      },
      formItemInternalMargin: {
        marginBottom: spacingUnit * 2,
      },
    },
    buttonLink: {
      padding: 0,
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      fontWeight: 500,
      color: paladinGreen,
      textAlign: 'left',
      transition: 'color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover, &:active, &:focus': {
        boxShadow: 'none',
        background: 'none',
        color: paladinGreenDark,
      },
    },
  },
  overrides: {
    MuiBadge: {
      colorSecondary: {
        backgroundColor: paladinGoldSolid,
      },
    },
    MuiButton: {
      root: {
        color: colors.brand[900],
        fill: colors.brand[900],
        height: '40px',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
        minWidth: '52px',
      },
      sizeSmall: {
        fontSize: '14px',
        fontWeight: 500,
        height: '26px',
        minHeight: '26px',
        padding: `0px 8px`,
        '& svg:not(.p-MuiCircularProgress-svg)': {
          marginTop: '-2px',
        },
      },
      sizeLarge: {
        height: '56px',
        fontSize: '14px',
        fontWeight: 500,
        padding: 0,
        '@media (min-width:600px)': {
          padding: '0px 20px',
        },
      },
      contained: {
        boxShadow: 'none',
        border: '1px solid transparent',
        '&:hover': {
          boxShadow: 'none',
        },
        '&$disabled': {
          color: white,
          backgroundColor: paladinNeutralGrayExtraLight,
          pointerEvents: 'inherit',
          '&:hover': {
            backgroundColor: paladinNeutralGrayExtraLight,
            border: '1px solid transparent',
          },
        },
      },
      containedPrimary: {
        color: white,
        fill: white,
        backgroundColor: colors.burgundy[900],
        '&:hover, &:active, &:focus': {
          color: 'white',
          backgroundColor: colors.burgundy[800],
        },
      },
      containedSecondary: {
        color: colors.brand[900],
        fill: colors.brand[900],
        backgroundColor: colors.brand[100],
        '&:hover': {
          backgroundColor: colors.brand[200],
          '@media (hover: none)': {
            backgroundColor: colors.brand[200],
          },
        },
        '&$disabled': {
          fill: 'white',
        },
      },
      outlined: {
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.1)',
        },
      },
      outlinedPrimary: {
        color: colors.burgundy[900],
        fill: colors.burgundy[900],
        borderColor: colors.burgundy[900],
        backgroundColor: white,

        '&:hover': {
          color: colors.burgundy[800],
          borderColor: colors.burgundy[800],
          backgroundColor: colors.burgundy[50],
        },
      },
      textPrimary: {
        color: paladinGreenDark,
      },
    },
    MuiAvatar: {
      colorDefault: { backgroundColor: 'transparent', fill: paladinGreen },
    },
    MuiDivider: {
      root: {
        height: '2px',
        backgroundColor: paladinGreenLight,
        marginBottom: '15px',
      },
      light: {
        height: '1px',
        marginBottom: 0,
      },
      vertical: {
        marginBottom: 0,
        height: '20px',
        verticalAlign: 'middle',
        display: 'inline-block',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: paladinBackgroundGray,
        overflowY: 'scroll',
      },
    },
    MuiDialogTitle: {
      root: {
        color: paladinNavy,
        padding: '25px 20px 20px 30px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: spacingUnit * 3,
        overflowY: 'scroll',
      },
    },
    MuiDialogContentText: {
      root: {
        display: 'inline',
        color: paladinNeutralGray,
        fontWeight: 'normal',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '20px 40px 20px 40px',
        margin: 0,
        '& button': {
          marginLeft: '5px',
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: white,
        color: paladinGreen,
        marginRight: spacingUnit,
        marginBottom: spacingUnit,
        paddingRight: spacingUnit,
        height: `${spacingUnit * 4}px`,
        border: `1px solid ${colors.brand['300']}`,
        borderRadius: '20px',
        '& $avatar': {
          width: '32px',
          height: '32px',
          marginRight: 0,
          marginLeft: 0,
        },
      },
      outlinedPrimary: {
        color: colors.brand['700'],
      },
      deleteIcon: {
        marginLeft: 0,
        '&:hover': { color: paladinGreenDark },
      },
      deletable: {
        paddingRight: '0px',
        '&:focus': { backgroundColor: paladinGreenDark },
      },
      avatar: {
        width: 'fit-content',
        marginRight: 0,
        paddingLeft: '16px',
      },
      label: { fontSize: '14px', fontWeight: '500' },
      clickable: {
        '&:hover ': { border: `1px solid ${paladinGreen}` },
      },
    },
    MuiSnackbar: {
      root: {
        marginTop: '56px', // #top-nav height
      },
      anchorOriginTopCenter: { top: '5%' },
    },
    MuiFormLabel: {
      root: { color: paladinNeutralGray },
    },
    MuiFormControl: {
      root: {
        position: 'inherit',
        zIndex: 'auto',
      },
    },
    MuiFormControlLabel: {
      root: { marginLeft: 0, marginRight: 0, marginBottom: 0 },
      label: {
        fontSize: '14px',
        fontWeight: 500,
        color: paladinGreen,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        height: 'unset',
        width: 'unset',
      },
      colorPrimary: { color: paladinGreen },
    },
    MuiFormHelperText: {
      root: {
        color: paladinNeutralGrayLight,
        lineHeight: '1.5em',
      },
    },
    MuiIconButton: {
      label: { width: 'unset', height: 'unset' },
    },
    MuiInput: {
      root: {
        background: white,
        padding: spacingUnit,
        fontSize: '24px',
        fontFamily: titleFontFamily,
        fontVariantNumeric: 'lining-nums',
        fontWeight: 'normal',
        color: paladinNavy,
        lineHeight: '30px',
        width: '300px',
        height: '50px',
        '&$error': { color: errorRed },
      },
      colorSecondary: {
        background: paladinGreenDark,
        color: white,
      },
      fullWidth: {
        width: '100%',
      },
      multiline: { height: 'auto', padding: spacingUnit },
      input: {
        padding: 0,
        width: '100%',
        height: '100%',
        '&::placeholder': {
          color: paladinNeutralGrayLight,
        },
      },
      underline: {
        '&:after': { borderBottomColor: paladinGreen },
      },
    },
    MuiInputBase: {
      inputAdornedStart: {
        marginLeft: spacingUnit,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px',
        fontFamily: titleFontFamily,
        color: paladinNavy,
        lineHeight: '30px',
        '&$selected': {
          backgroundColor: paladinGreenLight,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '30px',
        fontFamily: titleFontFamily,
        fontWeight: '600',
      },
      h2: {
        fontSize: '24px',
        fontFamily: titleFontFamily,
        fontWeight: '500',
      },
      h3: {
        fontFamily: titleFontFamily,
        fontWeight: '500',
      },
      h6: {
        fontSize: '16px',
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: '18px',
        fontFamily: titleFontFamily,
        fontWeight: 500,
      },
      body1: {
        fontSize: '19px',
        fontFamily: titleFontFamily,
        color: paladinNeutralGray,
      },
      body2: {
        fontSize: '14px',
        fontFamily: textFontFamily,
        color: paladinNeutralGray,
      },
      caption: {
        fontSize: '12px',
        fontFamily: textFontFamily,
        color: paladinNeutralGray,
        lineHeight: '1.375em',
      },
      colorPrimary: {
        color: paladinGreenDark,
      },
    },
    MuiRadio: {
      root: {
        padding: '10px 10px 10px 0',
      },
    },
    MuiTableHead: {
      root: {
        borderTop: `1px solid ${paladinGreenLight}`,
      },
    },
    MuiTableRow: {
      head: {
        '&:first-child': {
          boxShadow: 'none',
        },
        '&:nth-child(odd)': {
          backgroundColor: white,
        },
        '& th + th': {
          borderLeft: `1px solid ${paladinGreenLight}`,
        },
      },
      root: {
        '&:first-child': {
          boxShadow: 'inset 0 12px 6px -10px #e5e8ef',
        },
        '&:nth-child(odd)': {
          backgroundColor: white,
        },
        '&:nth-child(even)': {
          backgroundColor: paladinGreenExtraLight,
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '0px',
        lineHeight: '1rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      head: {
        fontWeight: 500,
        padding: '14px 8px',
      },
    },
    MuiPagination: {
      ul: {
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
        '& li + li': {
          borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiPaginationItem: {
      outlined: {
        border: 'none',
        borderTop: '1px solid rgba(0, 0, 0, 0.23)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
      },
      rounded: {
        borderRadius: 0,
      },
      ellipsis: {
        lineHeight: '40px',
      },
      page: {
        margin: 0,
        color: paladinGreenDark,
        '&$disabled': {
          color: paladinNeutralGrayExtraLight,
          opacity: 'initial',
        },
        '&$selected': {
          backgroundColor: paladinGreenDark,
          color: white,
          '&:hover': {
            backgroundColor: paladinGreen,
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        fontWeight: 500,
        backgroundColor: '#1D1D1D',
        padding: '15px',
        textAlign: 'center',
        maxWidth: '420px',
      },
    },
    MuiAlert: {
      root: {
        fontWeight: 500,
      },
      standardError: {
        backgroundColor: paladinRedLight,
        color: paladinRedDark,

        '& $icon': {
          color: paladinRedDark,
        },
      },
      standardSuccess: {
        backgroundColor: paladinGreenLight,
        color: paladinGreenDark,

        '& $icon': {
          color: paladinGreenDark,
        },
      },
      standardInfo: {
        backgroundColor: paladinGreenLight,
        color: paladinGreenDark,

        '& $icon': {
          color: paladinGreenDark,
        },
      },
    },
    MUIRichTextEditor: {
      editor: {
        height: '300px',
        overflow: 'scroll',
        background: white,
        padding: spacingUnit,
        fontSize: '24px',
        fontFamily: titleFontFamily,
        fontVariantNumeric: 'lining-nums',
        fontWeight: 'normal',
        color: paladinNavy,
        lineHeight: '30px',
      },
      placeHolder: {
        height: '300px',
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: paladinGreenLight,
      },
    },
  },
  props: {
    MuiButton: { variant: 'contained' },
    MuiDialog: { scroll: 'body', fullWidth: true },
    MuiDialogContentText: { variant: 'subtitle1' },
    MuiTypography: { variant: 'body2' },
  },
  paladinBreakpoints: {
    xs: 320,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
});

export default paladinTheme;
