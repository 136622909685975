import {
  isArray,
  isBoolean,
  isNumber,
  isDate,
  isEmpty,
  isObject,
  reduce,
  get,
} from 'lodash';
import { isString } from 'lodash/lang';

export function addEventListener(event, element, callback) {
  if (element.addEventListener)
    element.addEventListener(event, callback, false);
  else if (element.attachEvent) {
    element.attachEvent(`on${event}`, callback);
  }
}

// use: forEach(myNodeList, function (index, value) { console.log(index, value); });
export function forEachEl(array, callback, scope) {
  for (let i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]);
  }
}

export function addClass(element, className) {
  if (!containsClass(element, className)) {
    element.className += ` ${className}`;
  }
}

export function removeClass(element, className) {
  element.className = element.className.replace(` ${className}`, '');
}

export function containsClass(element, className) {
  return element.className.indexOf(className) > -1;
}

export function toggleClass(element, className) {
  if (containsClass(element, className)) {
    removeClass(element, className);
  } else {
    addClass(element, className);
  }
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function maybeInitialize(element, func) {
  if (element) {
    func(element);
  }
}

export function maybeInitializeClass(element, klass) {
  if (element) {
    new klass(element);
  }
}

export function isUndefinedOrEmpty(value) {
  if (isNumber(value) || isDate(value) || isBoolean(value)) {
    return false;
  }

  if ((isArray(value) || isObject(value)) && isEmpty(value)) {
    return true;
  }

  return !value;
}

export function transformShape(data, transformations) {
  return reduce(
    transformations,
    (acc, transformer, key) => {
      const value = isString(transformer)
        ? get(data, transformer)
        : transformer(data);

      if (!isUndefinedOrEmpty(value)) {
        acc[key] = value;
      }

      return acc;
    },
    {},
  );
}

export function copyToClipboardWithStyling(elementId) {
  window.getSelection().removeAllRanges();
  const range = document.createRange();
  range.selectNode(document.getElementById(elementId));
  window.getSelection().addRange(range);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
}

export const reloadPage = () => window.location.reload();
export const redirect = newUrl => window.location.assign(newUrl);

export function getQueryParams() {
  return new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
}
