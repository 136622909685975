import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import paladinTheme from '../paladinTheme';

export default function ThemedComponent({ generateClassName, children }) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={paladinTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StylesProvider>
  );
}

ThemedComponent.propTypes = {
  children: PropTypes.element.isRequired,
  generateClassName: PropTypes.func.isRequired,
};
