import { GET_SELECTED } from 'gql';

const resolvers = {
  Mutation: {
    toggleAll: (_, { allItems, groups }, { cache }) => {
      const { selected } = cache.readQuery({ query: GET_SELECTED });
      let newSelected = selected.length > 0 ? [] : allItems;
      if (groups?.length) {
        const selectedNotInGroup = selected.filter(
          item => !groups.includes(item.value.group),
        );
        const selectedInGroup = selected.filter(item =>
          groups.includes(item.value.group),
        );
        newSelected = selectedInGroup.length > 0 ? [] : allItems;
        newSelected = newSelected.concat(selectedNotInGroup);
      }

      cache.writeQuery({
        query: GET_SELECTED,
        data: { selected: newSelected },
      });

      return newSelected;
    },
  },
};

export default resolvers;
