import React from 'react';
import { Dialog as MaterialDialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogTitle from './DialogTitle';
import DialogContent from './DialogContent';

const fullScreenDialogBreakpoint = 'xs';

const styles = theme => ({
  actions: {
    '& button': {
      minWidth: '100px',
    },
    [theme.breakpoints.down(fullScreenDialogBreakpoint)]: {
      margin: theme.spacing(1),
      display: 'block',
      '& button': {
        width: '100%',
        marginBottom: '5px',
        marginLeft: 0,
      },
    },
  },
  banner: {
    backgroundColor: theme.palette.primary.extraLight,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    margin: `${theme.spacing(1)}px`,
    marginBottom: '0px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  errorMessage: {
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down(fullScreenDialogBreakpoint)]: {
      marginTop: -theme.spacing(2),
    },
  },
});

const Dialog = ({
  classes,
  handleClose,
  fullScreen,
  actions,
  title,
  titleEmphasis,
  titleIcon,
  titleIconPosition,
  message,
  children,
  bannerHeader,
  bannerBody,
  errorMessage,
  divider,
}) => (
  <MaterialDialog
    fullScreen={fullScreen}
    onClose={handleClose}
    open
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {typeof title === 'string' ? (
      <DialogTitle
        title={title}
        titleEmphasis={titleEmphasis}
        handleClose={handleClose}
        titleIcon={titleIcon}
        titleIconPosition={titleIconPosition}
      />
    ) : (
      <>{title}</>
    )}
    {divider || <Divider light />}
    {(bannerHeader || bannerBody) && (
      <div className={classes.banner}>
        <Typography variant="body2" color="primary" paragraph={!!bannerBody}>
          <Box fontWeight="fontWeightBold" component="span">
            {bannerHeader}
          </Box>
        </Typography>
        <Typography variant="body2">{bannerBody}</Typography>
      </div>
    )}
    <DialogContent message={message}>{children}</DialogContent>
    {actions && (
      <DialogActions disableSpacing className={classes.actions}>
        {actions}
      </DialogActions>
    )}
    {errorMessage && (
      <FormHelperText error className={classes.errorMessage}>
        {errorMessage}
      </FormHelperText>
    )}
  </MaterialDialog>
);

Dialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  message: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      bold: PropTypes.bool,
      linkHref: PropTypes.string,
      linkTitle: PropTypes.string,
    }),
  ),
  title: PropTypes.string.isRequired,
  titleEmphasis: PropTypes.bool,
  bannerHeader: PropTypes.string,
  bannerBody: PropTypes.string,
  handleClose: PropTypes.func,
  children: PropTypes.node,
  titleIcon: PropTypes.element,
  titleIconPosition: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node),
  errorMessage: PropTypes.string,
  divider: PropTypes.element,
};

Dialog.defaultProps = {
  handleClose: undefined,
  titleIcon: undefined,
  children: undefined,
  message: undefined,
  actions: undefined,
  bannerHeader: undefined,
  bannerBody: undefined,
  titleEmphasis: false,
  titleIconPosition: 'left',
  errorMessage: undefined,
  divider: undefined,
};

export default withStyles(styles)(
  withMobileDialog({
    breakpoint: fullScreenDialogBreakpoint,
  })(Dialog),
);
