import gql from 'graphql-tag';

export const GET_SELECTED = gql`
  query getSelected {
    selected @client
  }
`;

export const AUDIENCE_FRAGMENT = gql`
  fragment audienceFragment on AudienceObjectType {
    id
    name
    label
  }
`;

export const CAMPAIGN_FRAGMENT = gql`
  fragment campaignFragment on CampaignObjectType {
    id
    name
    label
  }
`;

export const ENGAGEMENT_TYPE_FRAGMENT = gql`
  fragment engagementTypeFragment on EngagementTypeObjectType {
    id
    name
    label
    category
  }
`;

export const PRACTICE_AREA_FRAGMENT = gql`
  fragment practiceAreaFragment on PracticeAreaObjectType {
    id
    name
    label
  }
`;

export const CLIENT_TYPE_FRAGMENT = gql`
  fragment clientTypeFragment on ClientTypeObjectType {
    id
    name
    label
  }
`;

export const SKILL_FRAGMENT = gql`
  fragment skillFragment on SkillObjectType {
    id
    name
  }
`;

export const REGION_FRAGMENT = gql`
  fragment regionFragment on RegionObjectType {
    name
    geonameCode
  }
`;

export const LANGUAGE_FRAGMENT = gql`
  fragment languageFragment on LanguageObjectType {
    id
    code
    label
  }
`;

export const AUDIENCE_QUERY = gql`
  {
    audience {
      ...audienceFragment
    }
  }
  ${AUDIENCE_FRAGMENT}
`;

export const ENGAGEMENT_TYPES_QUERY = gql`
  query engagementTypes($category: EngagementTypeCategory = PRO_BONO) {
    engagementTypes(category: $category) {
      ...engagementTypeFragment
    }
  }
  ${ENGAGEMENT_TYPE_FRAGMENT}
`;

export const PRACTICE_AREAS_QUERY = gql`
  {
    practiceAreas {
      ...practiceAreaFragment
    }
  }
  ${PRACTICE_AREA_FRAGMENT}
`;

export const CLIENT_TYPES_QUERY = gql`
  {
    clientTypes {
      ...clientTypeFragment
    }
  }
  ${CLIENT_TYPE_FRAGMENT}
`;

export const SKILLS_QUERY = gql`
  query skills {
    skills {
      ...skillFragment
    }
  }
  ${SKILL_FRAGMENT}
`;

export const LANGUAGES_QUERY = gql`
  {
    languages {
      ...languageFragment
    }
  }
  ${LANGUAGE_FRAGMENT}
`;

export const TEAM_FRAGMENT = gql`
  fragment TeamFragment on TeamObjectType {
    id
    name
    organization {
      id
      name
      hasPublicPortal
      partnersAs {
        name
      }
    }
  }
`;

export const TEAMS_BY_EXCLUSIONS_QUERY = gql`
  query OpportunityExclusionsByTeam(
    $permission: String!
    $opportunityIds: [UUID]!
  ) {
    oppExclusionsSummary(
      permission: $permission
      opportunityIds: $opportunityIds
    ) {
      teamsWithNoExclusions {
        ...TeamFragment
      }
      mixedPermissionTeams {
        ...TeamFragment
      }
      disallowedTeams {
        ...TeamFragment
      }
    }
  }
  ${TEAM_FRAGMENT}
`;

export const OPPORTUNITY_RECRUITING_TEAMS_QUERY = gql`
  query opportunity($id: UUID) {
    opportunity(id: $id) {
      id
      recruitingTeams {
        ...TeamFragment
      }
    }
  }
  ${TEAM_FRAGMENT}
`;

export const USER_OPPORTUNITY_PARTICIPATION_FRAGMENT = gql`
  fragment UserOpportunityParticipationFragment on UserOpportunityParticipationObjectType {
    id
    status {
      name
    }
  }
`;

export const LISTING_FACETS_QUERY = gql`
  query listingFacets($portalSlug: String!) {
    listingFacets(portalSlug: $portalSlug) {
      practiceAreas {
        ...practiceAreaFragment
      }
      clientTypes {
        ...clientTypeFragment
      }
      engagementTypes {
        ...engagementTypeFragment
      }
      audience {
        ...audienceFragment
      }
      regions {
        ...regionFragment
      }
      campaigns {
        ...campaignFragment
      }
    }
  }
  ${PRACTICE_AREA_FRAGMENT}
  ${CLIENT_TYPE_FRAGMENT}
  ${ENGAGEMENT_TYPE_FRAGMENT}
  ${AUDIENCE_FRAGMENT}
  ${REGION_FRAGMENT}
  ${CAMPAIGN_FRAGMENT}
`;

export const UPDATE_USER_OPPORTUNITY_PARTICIPATION_MUTATION = gql`
  mutation updateUserOpportunityParticipation(
    $input: UserOpportunityParticipationMutationInput!
  ) {
    createOrUpdateUserOpportunityParticipation(input: $input) {
      userOpportunityParticipation {
        ...UserOpportunityParticipationFragment
      }
    }
  }
  ${USER_OPPORTUNITY_PARTICIPATION_FRAGMENT}
`;

export const extractUserOpportunityParticipation = data =>
  data.createOrUpdateUserOpportunityParticipation.userOpportunityParticipation;

export const buildUpdateUserOpportunityParticipationMutationInput = ({
  participationId,
  userId,
  ovrId,
  uopStatus,
}) => ({
  input: {
    id: participationId,
    user: userId,
    opportunityVolunteerRequirement: ovrId,
    status: uopStatus,
  },
});

export const GET_COUNTRIES_QUERY = gql`
  query GetCountries {
    countries {
      id
      name
      code2
    }
  }
`;
