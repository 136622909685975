import 'whatwg-fetch';
import React from 'react';

export function retry(fn, triesLeft = 5, interval = 100) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        const newTriesLeft = triesLeft - 1;

        setTimeout(() => {
          if (newTriesLeft === 0) {
            reject(error);
            return;
          }

          retry(fn, newTriesLeft, interval * 2).then(resolve, reject);
        }, interval);
      });
  });
}

export function lazyLoad(dynamicImport) {
  return React.lazy(() => retry(dynamicImport));
}
