import React from 'react';
import MaterialButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  startIcon: {
    marginRight: '5px',
  },
  hideSpinner: {
    display: 'none',
  },
  hideLabelText: {
    display: 'none',
  },
  centered: {
    textAlign: 'center',
    fontWeight: '500',
  },
};

const Button = React.forwardRef(
  (
    {
      label,
      inProgress,
      disabled,
      disabledMessage,
      startIcon,
      endIcon,
      classes,
      size,
      errorMessage,
      ...childProps
    },
    ref,
  ) => {
    const labelTextClass = inProgress ? classes.hideLabelText : '';
    const spinnerClass = !inProgress ? classes.hideSpinner : '';

    const OptionalTooltip =
      disabled && disabledMessage ? Tooltip : React.Fragment;
    const tooltipProps =
      disabled && disabledMessage ? { title: disabledMessage } : {};

    return (
      <React.Fragment>
        <OptionalTooltip {...tooltipProps}>
          <MaterialButton
            ref={ref}
            disabled={inProgress || disabled}
            size={size}
            {...childProps}
          >
            <div className={labelTextClass}>
              {startIcon && (
                <span className={classes.startIcon}>{startIcon}</span>
              )}
              <span>{label}</span>
              {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
            </div>
            <CircularProgress
              disableShrink
              color="inherit"
              size={size === 'small' ? 16 : 20}
              className={spinnerClass}
            />
          </MaterialButton>
        </OptionalTooltip>
        {errorMessage && (
          <FormHelperText className={classes.centered} error>
            {errorMessage}
          </FormHelperText>
        )}
      </React.Fragment>
    );
  },
);

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  variant: PropTypes.oneOf(['outlined', 'contained', 'text']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  inProgress: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  errorMessage: PropTypes.string,
};

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
  variant: 'contained',
  disabled: false,
  disabledMessage: '',
  fullWidth: false,
  inProgress: false,
  startIcon: undefined,
  endIcon: undefined,
  label: undefined,
  errorMessage: '',
};

export default withStyles(styles)(Button);
