import React from 'react';
import GenericErrorDialog from 'components/GenericErrorDialog';
import PropTypes from 'prop-types';
import { reportError } from 'utils/errorUtils';

export default class BlockingErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    reportError(error, info);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <GenericErrorDialog />;
    }

    return children;
  }
}

BlockingErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};
