import React from 'react';
import Typography from '@material-ui/core/Typography';
import MaterialDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  flexContainer: {
    display: 'flex',
  },
  titleEmphasis: {
    fontStyle: 'italic',
    color: theme.palette.primary.emphasis,
    fill: theme.palette.primary.emphasis,
  },
  titleStandard: {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
  },
  titleContent: {
    flex: 1,
  },
  titleIcon: {
    alignSelf: 'center',
    marginRight: theme.spacing(1),
    fill: theme.palette.secondary.main,
    '& svg g': {
      fill: 'inherit',
    },
  },
  close: {
    flexGrow: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
});

const DialogTitle = ({
  classes,
  title,
  titleIcon,
  handleClose,
  titleEmphasis,
  titleIconPosition,
}) => {
  let titleContainerClasses = `${classes.titleContent} ${
    classes.flexContainer
  } `;
  titleContainerClasses += titleEmphasis
    ? classes.titleEmphasis
    : classes.titleStandard;

  return (
    <MaterialDialogTitle
      className={classes.flexContainer}
      id="alert-dialog-title"
      disableTypography
    >
      <div className={titleContainerClasses}>
        {titleIcon && titleIconPosition === 'left' && (
          <div className={classes.titleIcon}>{titleIcon}</div>
        )}
        <Typography color="inherit" variant="subtitle1">
          {title}
        </Typography>
        {titleIcon && titleIconPosition === 'right' && (
          <div className={classes.titleIcon}>{titleIcon}</div>
        )}
      </div>
      {handleClose && (
        <IconButton
          color="primary"
          className={classes.close}
          onClick={handleClose}
          disableRipple
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MaterialDialogTitle>
  );
};

DialogTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  titleEmphasis: PropTypes.bool,
  handleClose: PropTypes.func,
  titleIcon: PropTypes.element,
  titleIconPosition: PropTypes.string,
};

DialogTitle.defaultProps = {
  handleClose: undefined,
  titleIcon: undefined,
  titleEmphasis: false,
  titleIconPosition: 'left',
};

export default withStyles(styles)(DialogTitle);
export const UnstyledDialogTitle = DialogTitle;
